@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.port19_main{
    /* background-color: darkslategrey;
    background-image: -webkit-linear-gradient(160deg, #2690c0 50%, #F8933C 10%   ); */
    background:linear-gradient(270deg , #FB923C,#212121);
}
.port19_left{
    height: 400px !important;
    overflow: hidden !important;
    position: relative !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 20px;
}
.port19_left a img{
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    transition: top 4s ease !important;
    cursor: pointer !important;
}
.port19_left a img:hover {
    top: -240px !important;
}
.port19_right{
    padding: 7% 5% 7% 7% !important;
    color: white !important;
    text-align: left !important;
    
}
.portfolio19_btn{
    color: white !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    border: 2px solid white !important;
    background: none !important;
    border-radius: 25px !important;
    transition: all 0.8s !important;
}
.portfolio19_btn:hover{
    background-color: white !important;
    border: 2px solid white !important;
    color: black !important;
}
.port19_right h4 span{
    font-weight: 300  !important;
    line-height: 4px !important;
}
.custom-rounded {
    border-radius: 0 025em 25rem 0;
    font-size: 12px;
    font-weight: bold;
    flex-wrap: nowrap;
    text-wrap: nowrap;
  }
  
.port19_divider{
    border-top: 8px solid white !important;
    padding: 0px !important;
    margin: 20px 0px 20px 0px !important;
}
.port19_right p {
    opacity: 0.9 !important;
}
@media screen and (max-width: 768px) {
    .slick-slide img {
        display: block !important;
        width: 100% !important;
        max-width: 700px !important;
        height: 300px !important;
    }
    
.port19_left{
    height: 330px !important;
    overflow: hidden !important;
    position: relative !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-radius: 20px;
}
}

@media screen and (max-width: 480px) {
    .slick-slide img {
        display: block !important;
        width: 100% !important;
        max-width: 400px !important;
    }

    .custom-rounded {
        border-radius: 0 025em 25rem 0;
        font-size: 8px;
        font-weight: bold;
        flex-wrap: nowrap;
        text-wrap: nowrap;
      }
}
