@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.port12_main{
    /* background-color: #313647;
    background-image: -webkit-linear-gradient(35deg, #898989 50%, #F1C36D 10%   ); */
    background:linear-gradient(90deg, #0B0B0E , #4A2513);
}
.port12_right{
    padding-top: 3%;
    padding-bottom: 3%;
}
.port12_left{
    padding-top: 10%;
    padding-bottom: 6%;
    color: white;
    text-align: left;
}
.port12_left h4 span{
    font-weight: 300 ;
    line-height: 4px;
}
.port12_left p {
    opacity: 0.5;
}
.portfolio12_btn{
    color: white !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    border: 2px solid white !important;
    background: none !important;
    border-radius: 25px !important;
    transition: all 0.8s !important;
}
.portfolio12_btn:hover{
    background-color: white !important;
    border: 2px solid white !important;
    color: black !important;
}


.port12_right_inner {
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 4% 2%;
    box-sizing: border-box;
    height: 450px;
  }
  
  .box {
    flex: 1;
    overflow: hidden;
    transition: .5s;
    margin: 0 2%;
    box-shadow: 0 20px 30px rgba(0,0,0,.1);
    line-height: 0;
  }
  
  .box > a> img {
    width: 200%;
    height: calc(100% - 10vh);
    object-fit: cover; 
    transition: 1.5s;
  }
  

  
  .box:hover { flex: 1 1 100%; }
  .box:hover > a > img {
    width: 100%;
    height: 100%;
  }
   