@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.aboutus_main {
  padding: 5rem 0rem 5rem 0rem;
}
.aboutus_main p a {
  color: #4f4f4f !important;
}
.aboutus_main p a:hover {
  color: #0ca392 !important;
}
