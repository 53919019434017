.custom-accordion .accordion-button:not(.collapsed) {
    background-color: #00494D;
    color: #ffffff;
  }
  
  .custom-accordion .accordion-button.collapsed {
    background-color: #008374;
    color: #ffffff;
  }
  
  
  .accordion-body {
    background-color: #E0E0E0;
  }