@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.port_main3{
    /* background-color: darkslategrey;
    background-image: -webkit-linear-gradient(160deg, #212121 50%, #FFCA18 10%   ); */
    background:linear-gradient(90deg, #ca955c , #212121);
}
.Port3_left{
    padding-top: 4% !important;
    padding-bottom: 4% !important;
    padding-right: 8% !important;
    padding-left: 8% !important;
}
.port3_right{
    padding-top: 10% !important;
    padding-bottom: 6% !important;
    color: white !important;
    font-weight: 300 !important;
    text-align: left !important;
}
.port3_right h2{
  margin-left: 30px;
}
.port3_right h4 span{
    font-weight: 300  !important;
    line-height: 4px !important;
    margin-left: 30px;
}
.port3_right p {
    opacity: 0.9 !important;
    margin-left:30px;
}
.porftfolio3_btn{
    color: white !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    border: 2px solid white !important;
    background: none !important;
    border-radius: 25px !important;
    transition: all 0.8s !important;
    margin-left: 30px;
}
.porftfolio3_btn:hover{
    background-color: white !important;
    border: 2px solid white !important;
    color: black !important;
}

.gallery {
    --g: 8px;   /* the gap */
    --s: 400px; /* the size */ 
    display: grid !important;
    border-radius: 50% !important;
  }
  .gallery > img {
    grid-area: 1/1 !important;
    aspect-ratio: 1 !important;
    object-fit: cover !important;
    border-radius: 50% !important;
    transform: translate(var(--_x,0),var(--_y,0)) !important;
    cursor: pointer !important;
    z-index: 0 !important;
    transition: .5s, z-index 0s .5s !important;
  }
  .gallery img:hover {
    --_i: 1 !important;
    z-index: 1 !important;
    transition: transform .2s, clip-path .3s .2s, z-index 0s !important;
  }
  .gallery:hover img {
    transform: translate(0,0) !important;
  }
  .gallery > img:nth-child(1) {
    clip-path: polygon(50% 50%,calc(50%*var(--_i,0)) calc(120%*var(--_i,0)),0 calc(100%*var(--_i,0)),0 0,100% 0,100% calc(100%*var(--_i,0)),calc(100% - 50%*var(--_i,0)) calc(120%*var(--_i,0)))  !important;
    --_y: calc(-1*var(--g))
  }
  .gallery > img:nth-child(2) {
    clip-path: polygon(50% 50%,calc(100% - 120%*var(--_i,0)) calc(50%*var(--_i,0)),calc(100% - 100%*var(--_i,0)) 0,100% 0,100% 100%,calc(100% - 100%*var(--_i,0)) 100%,calc(100% - 120%*var(--_i,0)) calc(100% - 50%*var(--_i,0)));
    --_x: var(--g)
  }
  .gallery > img:nth-child(3) {
    clip-path: polygon(50% 50%,calc(100% - 50%*var(--_i,0)) calc(100% - 120%*var(--_i,0)),100% calc(100% - 120%*var(--_i,0)),100% 100%,0 100%,0 calc(100% - 100%*var(--_i,0)),calc(50%*var(--_i,0)) calc(100% - 120%*var(--_i,0)));
    --_y: var(--g)
  }
  .gallery > img:nth-child(4) {
    clip-path: polygon(50% 50%,calc(120%*var(--_i,0)) calc(50%*var(--_i,0)),calc(100%*var(--_i,0)) 0,0 0,0 100%,calc(100%*var(--_i,0)) 100%,calc(120%*var(--_i,0)) calc(100% - 50%*var(--_i,0)));
    --_x: calc(-1*var(--g))
  }