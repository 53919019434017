@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.footer-links li a {
  color: white !important;
}
a {
  color: white !important;
}
.footer-links li {
  transition: all 0.5s;
}
.footer-links li:hover {
  transform: translateX(6px);
}
.footer-links h4 {
  font-size: 20px !important;
}
.footer-links li {
  font-size: 16px;
}
.footer-info p {
  font-size: 16px !important;
}
.copyright {
  font-size: 16px !important;
}
.credits {
  font-size: 16px !important;
}
