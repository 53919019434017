@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.port2_main{
    /* background-color: #313647;
    background-image: -webkit-linear-gradient(35deg, #393939 50%, #C39464 10%); */
    background:linear-gradient(90deg, #393939 , #C39464);
}
.port2_right{
    padding-top: 4% !important;
}
.port2_left{
    padding: 7% 5% 7% 5% !important;
    color: white !important;
    text-align: left !important;
}
.port2_left h4 span{
    font-weight: 300  !important;
    line-height: 4px !important;
}
.port2_left p {
    opacity: 0.9 !important;
}
.porftfolio2_btn{
    color: white !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    border: 2px solid white !important;
    background: none !important;
    border-radius: 25px !important;
    transition: all 0.8s !important;
}
.porftfolio2_btn:hover{
    background-color: white !important;
    border: 2px solid white !important;
    color: black !important;
}
.port2_right{
    padding-top: 0px !important;
}
  .port2_right a img {
    --_g: 10% /45% 45% no-repeat linear-gradient(#000 0 0) !important;
    --m:
      left   var(--_i,0%) top    var(--_g),
      bottom var(--_i,0%) left   var(--_g),
      top    var(--_i,0%) right  var(--_g),
      right  var(--_i,0%) bottom var(--_g);
    -webkit-mask: var(--m);
            mask: var(--m);
    transition: .5s linear;
    cursor: pointer;
  }
  .port2_right a img:hover {
    --_i: 10%;
    filter: grayscale(0);
  }
  