.demo{ background: #008374; padding-top: 20px; padding-bottom: 20px;}
a:hover,a:focus{
    outline: none;
    text-decoration: none;
}
.tab .nav-tabs{
  justify-items: center;
    margin-bottom: 10px;
    justify-content: center;
}
.tab .nav-tabs li {
    margin-bottom: 10px;
}
.tab .nav-tabs li a{
    color: #fff;
    padding: 10px 20px;
    margin-left: 5px;
    margin-right: 5px;
    background: #00494d;
    text-shadow: 1px 1px 2px #000;
    border: none;
    border-radius: 0;
    opacity: 1;
    position: relative;
    transition: all 0.3s ease 0s;
}
.tab .nav-tabs li a:hover{
    background: #00494d;
    opacity: 1;
}
.tab .nav-tabs li.active a{
    opacity: 1;
}
.tab .nav-tabs li.active a,
.tab .nav-tabs li.active a:hover,
.tab .nav-tabs li.active a:focus{
    color: #00494d!important;
    background: #fff;
    border: none;
    border-radius: 0;
}
ul li a{
    border-radius: 10px !important;
}

.tab .nav-tabs li a i,
.tab .nav-tabs li.active a i{
    display: inline-block;
    padding-right: 5px;
    font-size: 15px;
    text-shadow: none;
}
.tab .nav-tabs li a span{
    display: inline-block;
    font-size: 14px;
    letter-spacing: -9px;
    opacity: 0;
    transition: all 0.3s ease 0s;
}
.tab .nav-tabs li a:hover span,
.tab .nav-tabs li.active a span{
    letter-spacing: 1px;
    opacity: 1;
    transition: all 0.3s ease 0s;
}
.tab .tab-content {
    overflow: hidden;
    border-radius: 24px !important;
    padding: 0px;
    background: #fff;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    color: #6c6c6c;
    line-height: 25px;
}

.tab .tab-content h3{
    font-size: 24px;
    margin-top: 0;
}
@media only screen and (max-width: 479px){
    /* .tab .nav-tabs li{
        width: 100%;
        margin-bottom: px;
        text-align: center;
    }
    .tab .nav-tabs li a span{
        letter-spacing: 1px;
        opacity: 1;
    } */
    .icon-size{
        font-size: 12px !important;
    }
    .tab .nav-tabs{
        justify-items: center;
          margin-bottom: 6px;
          justify-content: center;
      }
.tab .nav-tabs li a{
    color: #fff;
    padding: 4px 8px;
    margin-left: 2px;
    margin-right: 2px;
    background: #00494d;
    text-shadow: 1px 1px 2px #000;
    border: none;
    border-radius: 0;
    opacity: 1;
    position: relative;
    transition: all 0.3s ease 0s;
}
    .tab .nav-tabs li a i,
    .tab .nav-tabs li.active a i{
        display: inline-block;
        padding-right: 0px;
        font-size: 14px;
        text-shadow: none;
    }

    .tab .nav-tabs li a:hover span,
    .tab .nav-tabs li.active a span{
        letter-spacing: 1px;
        opacity: 1;
        transition: all 0.3s ease 0s;
    }
    .tab .nav-tabs li a span{
        font-size: 10px;
    }
}