@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.port11_main{
	color: white;
    /* background-color: darkslategrey;
    background-image: -webkit-linear-gradient(160deg, #161616 50%, #DF453E 10%   ); */
    background:linear-gradient(90deg, #B56A33 , #161616);
}

.port11_left{
	padding: 7% 5% 5% 0%;
}
.port11_right{
	padding: 7% 5% 7% 5%!important;
}
  ul {
	display: flex;
	list-style: none;
  padding-right: 30px;
  }
  ul li {
	position: relative;
  }
  .image,
  .shadow {
	transform: rotateX(45deg) rotateZ(-25deg);
	transform-style: preserve-3d;
	transition: .5s;
  }
  .image {
	cursor: pointer;
	border: 1px solid grey;
	position: relative;
  }
  .image img {
	vertical-align: bottom;
	filter: grayscale(50%);
	transition: .5s;
  }
  .image::before,
  .image::after {
	content: '';
	position: absolute;
  }
  .image::before {
	bottom: -8px;
	left: 0;
	background-color: #d0d0d0;
	width: 100%;
	height: 16px;
	transform: rotateX(90deg) translateY(-8px);
  }
  .image::after {
	top: 0;
	left: -8px;
	width: 16px;
	height: 100%;
	background-color: #bbb;
	transform: rotateY(90deg) translateX(8px);
  }
  .shadow {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	box-shadow: -35px 45px 15px rgba(0, 0, 0, .1);
  }
  ul li:hover .image,
  ul li:hover .shadow {
	transform: rotate(0);
  }
  ul li:hover .image img {
	filter: grayscale(0);
  }
  ul li:hover .shadow {
	box-shadow: 0 5px 40px rgba(0, 0, 0, .5);
  }
  .portfolio11_btn{
    color: white !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    border: 2px solid white !important;
    background: none !important;
    border-radius: 25px !important;
    transition: all 0.8s !important;
}
.portfolio11_btn:hover{
    background-color: white !important;
    border: 2px solid white !important;
    color: black !important;
}