.go-to-top-button {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    border: none;
    border-radius: 50%;
    background-color: #008374;
    color: #fff;
    font-size: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .go-to-top-button.visible {
    display: block;
    opacity: 01;
  }
  
  .fa-arrow-up {
    margin-top: 3px;
  }
  