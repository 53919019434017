
.custom{
  padding: 20px;
}
.custom div{
  background-color: #333;
  border-radius: 20px;
  padding: 2px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}
@media screen and (width <= 768px) {
  .custom div{
    height: 160px!important;
    padding: 4px;
  }
}
@media screen and (width <= 320px) {
  .custom div{
    height: 100px!important;
    padding: 4px;
  }
}
