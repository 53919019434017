@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.AboutUsHero {
  opacity: calc(0.97);
  background: linear-gradient(
      0deg,
      rgba(240, 146, 6, 0.5),
      rgba(0, 182, 161, 0.5)
    ),
    url(../images/Herobg1.webp);

  color: white;
  text-align: center;
  height: 500px;
  padding: 12%;
  padding-top: 12%;
  height: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 3rem;
}
.AboutUsHero h1 {
  font-size: 4rem;
}
.AboutUsHero p {
  font-size: 1.5rem;
}
.AboutUsHero p a:hover {
  color: orange !important;
}
