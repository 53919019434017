@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

.PortfolioHero {
    opacity: calc(0.97);
    background: linear-gradient(0deg, rgba(240, 146, 6, 0.5) , rgba(0, 182, 161, 0.5)), url(../images/Herobg1.webp);
    color: white;
    text-align: center;
    padding: 12%;
    padding-top: 12%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 3rem;
}

.PortfolioHero h1 {
    font-size: 4rem;
}

.PortfolioHero p {
    font-size: 1.5rem;
}

.PortfolioHero a:hover {
    color: orange!important;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .PortfolioHero h1 {
        font-size: 3rem;
    }

    .PortfolioHero p {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .PortfolioHero h1 {
        font-size: 2.5rem;
    }

    .PortfolioHero p {
        font-size: 1rem;
    }
}
