@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.color-nav{
    font-family: 'Poppins', sans-serif!important;    
    background-color: #008374;
    top: 0;
    position: sticky;
    z-index: 999;
    width: 100%;
} 
.color-nav-inner{
    background-color: #008374;   
}
.navbar_logo{
    height: 65px;
    width: 100px;
}
.navbar-light .navbar-toggler-icon {
    background-image: auto!important;

}
.top_main{
    background-color: #00796b;
}
.navbar{
    position:initial !important;
}
.top_left ul li{
    font-size: 11px;
    margin-top: 8px;
    margin-left: 2rem;
    color: white;
    height: 10px;
}
.top_left ul li a{
    color: white;
}
.top_right ul{
    float: right;
    height: 10px;
    text-align: right;
    margin-right: 4rem;
}
.top_right ul li{
    margin-top: 5px;
}
.top_right ul li a{
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-left: 2rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 3.5rem!important;
}
.nav-link:hover{
    transform: translateX(10px);
    color: #46F0E1!important;
}
.navbar-nav {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-left: auto;
}
.navbar_toggle{
    background-color: white;
    font-family: 'Poppins', sans-serif;     
    background-image: url(../images/menu.png)!important; 
    background-position: center;
    background-repeat: no-repeat; 
}
  @media screen and (max-width: 768px) {
    .top_right{
        padding-bottom: 10px; ;
    }
  }
  @media screen and (max-width: 480px) {
    .navbar_logo{
        height: 45px;
        width: 80px;
    }
  }
  @media screen and (max-width: 342px) {
    .top_left ul li{
        font-size: 9px;
        margin-top: 8px;
        margin-left: 2rem;
        color: white;
        height: 10px;
    }
    .top_right ul{
        float: right;
        height: 10px;
        text-align: right;
        margin-right: 2rem;
    }
  }
 