@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.port1_main {
  /* background-color: darkslategrey;
    background-image: -webkit-linear-gradient(160deg, #2690c0 50%, #F8933C 10%   ); */
  background: linear-gradient(
    270deg,
    rgba(57, 57, 57, 1),
    rgba(248, 147, 60, 1)
  );
}
.port1_left {
  height: 400px !important;
  overflow: hidden !important;
  position: relative !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  padding-right: 10rem !important;
}
.port1_left a img {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transition: top 7s ease !important;
  cursor: pointer !important;
}
.portfolio1_btn {
  color: white !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border: 2px solid white !important;
  background: none !important;
  border-radius: 25px !important;
  transition: all 0.8s !important;
}
.portfolio1_btn:hover {
  background-color: white !important;
  border: 2px solid white !important;
  color: black !important;
}
.port1_left a img:hover {
  top: -1600px !important;
}
.port1_right {
  padding: 7% 5% 7% 7% !important;
  color: white !important;
  text-align: left !important;
}
.port1_right h4 span {
  font-weight: 300 !important;
  line-height: 4px !important;
}
.port1_divider {
  border-top: 8px solid white !important;
  padding: 0px !important;
  margin: 20px 0px 20px 0px !important;
}
.port1_right p {
  opacity: 0.9 !important;
}
.porftfolio1_btn {
  color: white !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border: 2px solid white !important;
  background: none !important;
  border-radius: 25px !important;
  transition: all 0.8s !important;
}
.porftfolio1_btn:hover {
  background-color: white !important;
  border: 2px solid white !important;
  color: black !important;
}
